import axios from "axios";
export default {
    AddTraining(form) {
        return axios.post('/trainings', form, {
            // headers: {
            //     'Content-Type': 'multipart/form-data'
            // }
        })
    },
    Check(id) {
        return axios.get(`/check-trainings/${id}`)
    },
};