<template>
  <div class="relative  fontRg " dir="ltr" :class="show ? '' : ' '">
    <div>
      <transition name="fade" class="absolute">
        <div
          dir="ltr"
          ref="bgcolor"
          class=" mainnavbar h-12 md:h-20 lg:h-20 w-full z-50"
          :class="{ 'hidden-navbar': !showNavbar }"
        >
          <div
            class="flex justify-between items-center w-full max-w-8xl mx-auto"
          >
            <!-- logo start -->
            <div class="pl-2 xl:pl-8">
              <div ref="logoblack" class="m-4 w-40 navbar relative  z-50">
                <router-link :to="`/${$i18n.locale}`">
                  <img
                    src="@/assets/images/Header Logo.png"
                    alt=""
                    class="w-5/12 md:w-7/12 lg:w-full"
                  />
                </router-link>
              </div>
              <div ref="logowhite" class="m-4 md:w-40 navbar none  z-50">
                <router-link :to="`/${$i18n.locale}`">
                  <img
                    src="@/assets/images/Asset 17@300x.png"
                    alt=""
                    class="w-5/12 md:w-7/12 lg:w-full"
                    @click="toglelose()"
                  />
                </router-link>
              </div>
            </div>
            <!-- logo end -->
            <div
              class="flex items-start md:items-center px-2 xl:px-8 navbar mx-4"
            >
              <SelectLocale />
              <button
                id="togle"
                class="focus:outline-none lg:mt-6 ml-2 cursor-pointer text-xl md:text-4xl lg:text-5xl lg:mb-10 fontcolor -mr-2"
                @click="togleopen()"
                onclick="this.classList.toggle('opened');
                this.setAttribute('aria-expanded',
                 this.classList.contains('opened'))"
                aria-label="Main Menu"
              >
                <svg
                  class="w-8 md:w-12 h-8 md:h-12 md:mt-4 -m-1"
                  viewBox="0 0 100 100"
                >
                  <path
                    class=" line1"
                    :class="toggelColorHamborger ? 'line' : 'lineblack'"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path
                    :class="toggelColorHamborger ? 'line' : 'lineblack'"
                    class=" line2"
                    d="M 20,50 H 80"
                  />
                  <path
                    :class="toggelColorHamborger ? 'line' : 'lineblack'"
                    class=" line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div
      id="id1"
      class="fixed min-h-screen overflow-hidden "
      :class="{ 'w-full': show }"
    >
      <div
        dir="ltr"
        ref="myNav"
        class="fixed inset-y-0 z-20 overflow-y-auto  bg-black  bg-opacity-75 w-full"
        :class="[show ? ' relative block ' : 'hidden ']"
      >
        <div class="h-screen flex justify-end text-white">
          <div class="mt-24 w-48 z-20 pl-6 md:pl-6">
            <button @click.prevent="openNavwork" class="text-lg md:text-3xl block hover:underline focus:outline-none lg:hidden">
              {{ $t("nav.work") }}
            </button>

            <span @mouseover="openNavwork" class="hidden lg:inline-block">
              <router-link
                :to="`/${$i18n.locale}/`"
                class="hover:underline"
              >
                <span
                  @click="toglelose()"
                  class="text-lg md:text-3xl block "
                  style="width:max-content;"
                >
                  {{ $t("nav.work") }}
                </span>
              </router-link>
            </span>

            <router-link
              :to="`/${$i18n.locale}/people`"
              class="hover:underline"
            >
              <span
                @click="toglelose()"
                class="text-lg md:text-3xl block "
                style="width:max-content;"
              >
                {{ $t("nav.people") }}
              </span>
            </router-link>
            <router-link :to="`/${$i18n.locale}/about`" class="hover:underline">
              <span @click="toglelose()" class="text-lg md:text-3xl block">
                {{ $t("nav.about") }}
              </span>
            </router-link>
            <router-link
              :to="`/${$i18n.locale}/contact`"
              class="hover:underline"
            >
              <span @click="toglelose()" class="text-lg md:text-3xl block">
                {{ $t("nav.contact") }}
              </span>
            </router-link>
             <router-link   :to="`/${$i18n.locale}/job`" class="hover:underline">
              <span @click="toglelose()" class="text-lg md:text-3xl block">
                {{ $t("nav.job") }}
              </span>
            </router-link> 
             <router-link
              :to="`/${$i18n.locale}/training`"
              class="hover:underline"
            >
              <span @click="toglelose()" class="text-lg md:text-3xl block">
                {{ $t("nav.training") }}
              </span>
            </router-link>
             <router-link
              :to="`/${$i18n.locale}/culture`"
              class="hover:underline"
            >
              <span @click="toglelose()" class="text-lg md:text-3xl block">
                {{ $t("nav.culture") }}
              </span>
            </router-link>
          </div>

          <div class="mt-24 w-64 text-white z-20">
            <div v-show="works" :class="{ animaiton: works }">
              <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(1)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/almadar`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.5G") }}
                </router-link>
              </span>
                             <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(4)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/Dima`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.dima") }}
                </router-link>
              </span>
              <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(6)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/Dwaya`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.Dwaya") }}
                </router-link>
              </span>
                <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(7)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/Libyana`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.Libyana") }}
                </router-link>
              </span>
              <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(7)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/Tadawul`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.Tadawul") }}
                </router-link>
              </span>
              <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(2)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/AbnaYousef`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.abnaYousef") }}
                </router-link>
              </span>

              <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(3)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/AnharAlkhair`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.anharAlkhair") }}
                </router-link>
              </span>
              <span class="text-lg md:text-4xl w-full block" @click="toglelose()" @mouseover="mouseover(5)" @mouseleave="mouseleave()">
                <router-link :to="`/${$i18n.locale}/kidsAcademy`" class="hover:underline inline-block text-white px-4">
                  {{ $t("nav.kidsAcademy") }}
                </router-link>
              </span>


            </div>
          </div>




          <div class="absolute w-full h-full z-10"  
              :class="{
                'overlay-kidsacademy': kidsAcademy,
                'overlay-dima': dima,
                'overlay-abnayousef ': abnayousef,
                'overlay-anhar': anharAlkhair,
                'overlay-5g': almadar,
              }"
          >
          </div>

        </div>


        

      </div>
    </div>
  </div>
</template>
<script>
import SelectLocale from "@/components/SelectLocale.vue";

export default {
  components: {
    SelectLocale,
  },
  data() {
    return {
      abnayousef: false,
      anharAlkhair: false,
      almadar: false,
      dima: false,
      kidsAcademy: false,
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      show: false,
      works: false,
      toggelColorHamborger: false,
    };
  },
  methods: {
    togleopen() {
      let check = document
        .getElementById("togle")
        .getAttribute("aria-expanded");

      if (check == "false") {
        this.show = !this.show;
        document.getElementById("togle").classList.remove("opened");
        document.getElementById("local").classList.remove("loacal");
        document.getElementById("svg").classList.remove("svgcolor");
        this.$refs.logowhite.style.display = "none";
        this.$refs.logoblack.style.display = "block";
        this.$refs.bgcolor.style.background = "#ffffff";
        this.toggelColorHamborger = false;
        this.works= false
      }
      if (check == "true") {
        this.show = !this.show;
        document.getElementById("local").classList.add("loacal");
        document.getElementById("svg").classList.add("svgcolor");
        this.$refs.logowhite.style.display = "block";
        this.$refs.logoblack.style.display = "none";
        this.$refs.bgcolor.style.background = "";
        this.toggelColorHamborger = true;
        this.works= false
      }
    },
    toglelose() {
      this.show = !this.show;
      document.getElementById("togle").classList.remove("opened");
      document.getElementById("local").classList.remove("loacal");
      document.getElementById("svg").classList.remove("svgcolor");
      this.$refs.logowhite.style.display = "none";
      this.$refs.logoblack.style.display = "block";
      this.$refs.bgcolor.style.background = "#ffffff";
      this.toggelColorHamborger = false;
    },
    openNavwork() {
      this.works = !this.works;
    },
    onScroll() {
      const OFFSET = 60;
      if (
        window.pageYOffset == 0 &&
        (this.$route.path == "/" || this.$route.path == "")
      ) {
        this.$refs.logowhite.style.display = "block";
        this.$refs.logoblack.style.display = "none";

        return;
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        if (
          window.pageYOffset == 0 &&
          (this.$route.path == "/en" ||
            this.$route.path == "/ar" ||
            this.$route.path == "/en/" ||
            this.$route.path == "/ar/" ||
            this.show == "true")
        ) {
          document.getElementById("local").classList.add("loacal");
          document.getElementById("svg").classList.add("svgcolor");
          this.$refs.logowhite.style.display = "block";
          this.$refs.logoblack.style.display = "none";
          this.$refs.bgcolor.style.background = "";
          this.toggelColorHamborger = true;
          return;
        }
        if (this.show == true && window.pageYOffset >= 0) {
          document.getElementById("local").classList.add("loacal");
          document.getElementById("svg").classList.add("svgcolor");
          this.$refs.logowhite.style.display = "block";
          this.$refs.logoblack.style.display = "none";
          this.$refs.bgcolor.style.background = "";
          this.toggelColorHamborger = true;
          return;
        }
        document.getElementById("local").classList.remove("loacal");
        document.getElementById("svg").classList.remove("svgcolor");
        this.$refs.logowhite.style.display = "none";
        this.$refs.logoblack.style.display = "block";
        this.$refs.bgcolor.style.background = "#ffffff";
        this.toggelColorHamborger = false;
        return;
      }

      this.showNavbar = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
    },
    mouseover() {
 
    },
    mouseleave() {
      this.almadar = false;
      this.abnayousef = false;
      this.anharAlkhair = false;
      this.dima = false;
      this.kidsAcademy = false;
    },
  },
  mounted() {
    document.getElementById("svg").classList.add("svgcolor");
    document.getElementById("local").classList.add("loacal");
    this.toggelColorHamborger = true;
    this.$refs.logowhite.style.display = "block";
    this.$refs.logoblack.style.display = "none";
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll);
    const viewportMeta = document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1";
    document.head.appendChild(viewportMeta);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style>
.loacal {
  color: rgb(252, 252, 252);
}
svg.svgcolor {
  fill: rgb(255, 255, 255);
}
.navbar {
  height: 40px;
  transform: translate3d(0, 0, 0);
  transition: 1s all ease-out;
}

.mainnavbar {
  height: 60px;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 1s all ease-out;
}

.mainnavbar.hidden-navbar {
  box-shadow: none;
  transform: translate3d(0, -200%, 0);
}
.linkwork {
  left: 46%;
  margin-left: -21px;
  margin-top: 28px;
}


.overlay-5g {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 100%,
      rgba(0, 0, 0, 0) 95.53%
    ),
    url("../assets/images/almadar5G/almadar-cover.jpg");

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
     animation: fadeIn_g5 ease-in-out 1.5s;
  -webkit-animation: fadeIn_g5 ease-in-out 1.5s;
}
@keyframes fadeIn_g5 {
  0% {
    opacity: .3;
    top: -900px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

.overlay-anhar {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 100%,
      rgba(0, 0, 0, 0) 95.53%
    ),
    url("../assets/images/AnharAlkhair/anhar-nav.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
     animation: fadeIn_anhar ease-in-out 1.5s;
  -webkit-animation: fadeIn_anhar ease-in-out 1.5s;
}
@keyframes fadeIn_anhar {
  0% {
    opacity: .3;
    top: -900px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

.overlay-abnayousef {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 100%,
      rgba(0, 0, 0, 0) 95.53%
    ),
    url("../assets/images/abnayousef/06-BOTTLE.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
   animation: fadeIn_abnayousef ease-in-out 1.5s;
  -webkit-animation: fadeIn_abnayousef ease-in-out 1.5s;
}
@keyframes fadeIn_abnayousef {
  0% {
    opacity: .3;
    top: -900px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

.overlay-dima {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 100%,
      rgba(0, 0, 0, 0) 95.53%
    ),
    url("../assets/images/dima/dima-cover.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: fadeIn_dima ease-in-out 1.5s;
  -webkit-animation: fadeIn_dima ease-in-out 1.5s;
}
@keyframes fadeIn_dima {
  0% {
    opacity: .3;
    top: -900px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

.overlay-kidsacademy {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 100%,
      rgba(0, 0, 0, 0) 95.53%
    ),
    url("../assets/images/kidsAcademy/02-CREATION.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
   animation: fadeIn_kidsacademy ease-in-out 1.5s;
  -webkit-animation: fadeIn_kidsacademy ease-in-out 1.5s;
}
@keyframes fadeIn_kidsacademy {
  0% {
    opacity: .3;
    top: -900px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

.animaiton {
  animation: loadingJ ease 1s;
  -webkit-animation: loadingJ ease 1s;
}
@keyframes loadingJ {
  0% {
    transform: translate(300px, 0);
  }

  101% {
    transform: translate(80px, 0);
  }
}


/*Hamburger*/
.lineblack {
  fill: none;
  stroke: rgb(0, 0, 0);
  stroke-width: 6;
  transition: stroke-dasharray 1000ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line {
  fill: none;
  stroke: rgb(255, 255, 255);
  stroke-width: 6;
  transition: stroke-dasharray 1000ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
</style>
