<template>
  <div id="app" class="" :dir="`${$i18n.locale}` == 'ar' ? 'rtl' : 'ltr'">
    <div v-if="hide==true">
      <Index  />
    </div>
    <div v-else>
      <Navbar class="z-50" />
      <router-view :class="`${$i18n.locale}` == 'ar' ? 'fontAkmd' : ''" class="z-0" />
      <Footer />
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer2.vue";
import Index from "@/components/indexpage.vue";

export default {
  components: {
    Navbar,
    Footer,
    Index,
  },
  data() {
    return {
      hide:false,
    };
  },
  mounted() {},
};
</script>

<style src='./assets/css/tailwind.css'></style>

<style>
html {
  scroll-behavior: smooth;
 }
@font-face {
  font-family: "LabilGrotesk-Rig";
  src: url("~@/assets/fonts/LabilGrotesk-Regular.ttf");
}
body {
  font-family: "LabilGrotesk-Light";
}

@font-face {
  font-family: "LabilGrotesk-Bold";
  src: url("~@/assets/fonts/LabilGrotesk-Bold.ttf");
}
.fontRg {
  font-family: "LabilGrotesk-Rig";
}

@font-face {
  font-family: "LabilGrotesk-Light";
  src: url("~@/assets/fonts/LabilGrotesk-Light.ttf");
}
.fontLt {
  font-family: "LabilGrotesk-Light";
}
.fontBold {
  font-family: "LabilGrotesk-Bold";
}
/*
ar


*/ 

@font-face {
  font-family: "AktivGroteskCorp-Regular";
  src: url("~@/assets/fonts/arabic/AktivGroteskCorp-Regular.ttf");
}
.fontAkRg {
  font-family: "AktivGroteskCorp-Regular";
}
@font-face {
  font-family: "AktivGroteskCorp-Bold";
  src: url("~@/assets/fonts/arabic/AktivGroteskCorp-Bold.ttf");
}
.fontAkbold {
  font-family: "AktivGroteskCorp-Bold";
}
@font-face {
  font-family: "AktivGroteskCorp-Medium";
  src: url("~@/assets/fonts/arabic/AktivGroteskCorp-Medium.ttf");
}
.fontAkmd {
  font-family: "AktivGroteskCorp-Medium";
}
/*
ar


*/ 
@font-face {
  font-family: "Tajawal-Bold";
  src: url("~@/assets/fonts/Tajawal-Bold.ttf");
}
.tajawalbold {
  font-family: "Tajawal-Bold";
}

@font-face {
  font-family: "Tajawal-Regular";
  src: url("~@/assets/fonts/Tajawal-Regular.ttf");
}
.tajawalRg {
  font-family: "Tajawal-Regular";
}
</style>
