//import axios from 'axios';
import HomeService from './HomeService.js';
import AboutService from './AboutService.js';
import ContactService from './ContactService.js';
import TeamService from './TeamService.js';
import JobService from './JobService.js';
import TrainingService from './TrainingService.js';



export default {
    homeService: HomeService,
    aboutService: AboutService,
    contactService: ContactService,
    teamService: TeamService,
    jobService: JobService,
    trainingService: TrainingService,

}