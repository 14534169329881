import axios from 'axios';

export default {

    GetSlider() {
        return axios.get(`/home-hero-images`)
    },
    HomeSection1Left(local) {
        return axios.get(`/home-section-1-lefts/${local}`)
    },
    HomeSection1Right(local) {
        return axios.get(`/home-section-1-rights/${local}`)
    },
    HomeSection2(local) {
        return axios.get(`/home-section-2-s/${local}`)
    },
    HomeSection3Left(local) {
        return axios.get(`/home-section-3-lefts/${local}`)
    },
    HomeSection3Right(local) {
        return axios.get(`/home-section-3-rights/${local}`)
    },
    HomeSection4(local) {
        return axios.get(`/home-section-4-s/${local}`)
    },
}